import { Link } from 'gatsby'
import EmailIcon from 'mdi-react/EmailIcon'
import React from 'react'
import { Helmet } from 'react-helmet'
import { ContentPage } from '../../components/content/ContentPage'
import { ContentSection } from '../../components/content/ContentSection'
import Layout from '../../components/Layout'
import TelegramIcon from '../../components/icons/telegram'
import WechatIcon from 'mdi-react/WechatIcon'
import QqchatIcon from 'mdi-react/QqchatIcon'
import PhoneIcon from 'mdi-react/PhoneIcon'

const title = '联系我们 - CodeIN'
const desc = '联系CodeIN支持工程师'

export default ((props: any) => (
    <Layout location={props.location}>
        <Helmet>
            <title>{title}</title>
            <meta name="twitter:title" content={title} />
            <meta property="og:title" content={title} />
            <meta name="twitter:description" content={desc} />
            <meta property="og:description" content={desc} />
            <meta name="description" content={desc} />
            <link rel="icon" type="image/png" href="/favicon.png" />
        </Helmet>
        <ContentPage title="联系我们" description={desc} className="text-dark" titleClassName="display-2 font-weight-bold">
            <ContentSection className="pt-6 pb-2">
                <div className="row">
                  <div className="col-md-6 mb-4 pb-1">
                        <div className="card">
                            <h5 className="card-header">售前售后支持</h5>
                            <div className="card-body">
                                <p className="card-text">购买/价格/售后等</p>
                                <div
                                    className="list-group-item list-group-item-action"
                                >
                                    <WechatIcon /> 加入微信
                                    <img src='/wechat.png'></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4 pb-1">
                        <div className="card">
                            <h5 className="card-header">产品支持</h5>
                            <div className="card-body">
                                <p className="card-text">有任何产品疑问可以:</p>
                            </div>
                            <div className="list-group list-group-flush">
                                <a
                                    className="list-group-item list-group-item-action"
                                    href="mailto:support@codein.dev"
                                    target="_blank"
                                >
                                    <EmailIcon /> 发送邮件 support@codein.dev
                                </a>
                                <a
                                        href="https://t.me/codeindev"
                                        target="_blank"
                                        className="list-group-item list-group-item-action"
                                    >
                                        <TelegramIcon width={25} height={25} /> 加入Telegram
                                    </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4 pb-1">
                        <div className="card">
                            <h5 className="card-header">其他</h5>
                            <div className="list-group list-group-flush">
                                <a
                                    className="list-group-item list-group-item-action"
                                    href="mailto:support@codein.dev"
                                    target="_blank"
                                >
                                    <EmailIcon /> support@codein.dev
                                </a>
                                <a
                                    className="list-group-item list-group-item-action"
                                    href="tel:+8615311676750"
                                    target="_blank"
                                >
                                    <PhoneIcon /> 手机：+8615311676750
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentSection>
        </ContentPage>
    </Layout>
)) as React.FunctionComponent<any>
