import React from 'react'

export const COLORS = {
    white: '',
    dark: 'bg-black text-light',
    purple: 'bg-purple text-light',
}

export const Jumbotron: React.FunctionComponent<{
    className?: string
    color?: keyof typeof COLORS
    title: string
    titleClassName?: string
    description?: string
    children: React.ReactNode
}> = ({
    className = '',
    color = 'white',
    title,
    titleClassName = 'display-3 font-weight-bold',
    description,
    children,
}) => (
    <div className={`jumbotron rounded-0 ${COLORS[color]} ${className}`}>
        <div className="container text-center pt-4 pb-5">
            <h1 className={titleClassName}>{title}</h1>
            {description && <p>{description}</p>}
            {children}
        </div>
    </div>
)
